import React, { Component } from "react";
// import { getTasks, addTask, removeTask, setTasks } from "./todo.service";

/**
 * Implement your TodoList here
 *
 * Use <code>getTasks, addTask, removeTask, setTasks</code> from the
 * <code>todo.service.js</code> as a backend service
 */
export default class TodoList extends Component {
  render() {
    return (
      <div className="container">
        Todo - implement this
      </div>
    );
  }
}
